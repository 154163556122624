<template>
<div>
    <div class="box p-5 zoom-in scroll-ajuste">
        <p class="font-medium mb-2" style="font-size: 16px">Cumplimiento Unidades</p>
        <div class="grid grid-cols-2 gap-4" >
            <div class=" gap-4">
                <div class="flex flex-col">
                    <span class="font-medium">
                        Total Und Solicitadas
                    </span>
                    <span class="text-gray-600 mt-2">
                        {{$h.formatNumber(dataCumplimiento.unidadesSolicitadas)}}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="font-medium">
                        Total Und con Entrega
                    </span>
                    <span class="text-gray-600 mt-2">
                        {{$h.formatNumber(dataCumplimiento.unidadesEntregadas)}}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="font-medium">
                        Und Pendientes
                    </span>
                    <span class="text-gray-600 mt-2">
                        {{$h.formatNumber(dataCumplimiento.unidadesPendientes)}}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="font-medium">
                        Und Agotados
                    </span>
                    <span class="text-gray-600 mt-2">
                        {{$h.formatNumber(dataCumplimiento.unidadesAgotadas)}}
                    </span>
                </div>
            </div>
            <div class=" gap-4">
                <!-- chart -->
                <div class="flex flex-col">
                    <span class="font-medium">
                        % Unidades Entregadas
                    </span>
                    <div id="chartjs-tooltip"></div>
                    <div class="flex-none mx-auto relative">
                        <ReportDonutChart1
                        :width="120"
                        :height="120"
                        :laData="[
                            dataCumplimiento.porcentUnidades,
                            100 - dataCumplimiento.porcentUnidades
                        ]"
                        :losLabels="losLabels"
                        :optionsCart="optionsChart"
                        />
                        <div
                        class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                        style="z-index:-1;"
                        >
                            {{dataCumplimiento.porcentUnidades}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import storeCardCumplimiento from './store/cardCumplimiento.store'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import { computed, ref, watch } from 'vue'
export default {
  name: 'cardCumplimiento',
  components: { ReportDonutChart1 },
  setup () {
    /** data **/
    const verMas = ref(false)
    /** computed **/
    const dataCumplimiento = computed(
      () => storeCardCumplimiento.getters.dataCumplimiento
    )

    const calcularPorcent = (a, b) => {
        return parseInt((b * 100) / a, 10)
    }

    const laData = ref([
            calcularPorcent(dataCumplimiento.value.unidadesSolicitadas, dataCumplimiento.value.unidadesEntregadas) + calcularPorcent(dataCumplimiento.value.referenciasSolicitadas, dataCumplimiento.value.unidadesAgotadas),
            100 - calcularPorcent(dataCumplimiento.value.unidadesSolicitadas, dataCumplimiento.value.unidadesEntregadas) - calcularPorcent(dataCumplimiento.value.unidadesSolicitadas, dataCumplimiento.value.unidadesAgotadas)
        ])
    const losLabels = ref(['Entregados', 'Pendientes'])

    const optionsChart = ref({
          tooltips: {
            trigger: 'item'
            },
          legend: {
            display: false
          },
          cutoutPercentage: 53
        })

    /** watch **/
    watch(verMas, (a, b) => {
      if (b) {
        getData()
      }
    })
    const getData = () => {
      storeCardCumplimiento.dispatch('getDataCumplimiento', { })
    }
    return {
      calcularPorcent,
      dataCumplimiento,
      optionsChart,
      laData,
      losLabels,
      verMas
    }
  }
}
</script>
<style scoped>
</style>
